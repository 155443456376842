import "./Guide.css";
import YoloBlue from '../../assets/icons/yolo-blue.png';
import Store from '../../assets/images/store.png';
import Bag from '../../assets/images/bag.png';
import Smile from '../../assets/images/smile.png';
import Check from '../../assets/images/check.png';
import MobileStore from '../../assets/icons/mobile-store.png';
import MobileBag from '../../assets/icons/mobile-bag.png';
import MobileSmile from '../../assets/icons/mobile-smile.png';
import MobileCheck from '../../assets/icons/mobile-check.png';

// Classes were avoided by managing styles using HTML tags.

// head: This is the title with the image.
// section: The container that holds the 4 images with their texts.
// article: Four containers each containing three smaller ones: one empty, one with an image, and one with text. This is done to keep the elements centered.
// div: These are three containers per article, responsible for storing the final content.
// class (dynamic / static - third): These are inside the divs and are exactly the same size, but the dynamic ones disappear on mobile, while the static ones are always present.

const Guide = () => {
    return (
        <main className="background-guide" id="home">
            <header>
                <h1>¿Cómo funciona</h1>
                <img className="yolo-logo-blue" src={YoloBlue} alt="Yolo" />
                <h1>?</h1>
            </header>
            <section>
                <article>
                    <div className="dynamic-third"></div>
                    <div className="static-third">
                        <img className="image" src={Store} alt="Store" />
                        <img className="image-mobile" src={MobileStore} alt="Store" />
                    </div>
                    <div className="static-third">
                        <h5 className="first-text">El cliente se acerca a tu comercio</h5>
                    </div>
                </article>
                <article>
                    <div className="static-third">
                        <h5 className="second-text">Elige el producto que necesita</h5>
                    </div>
                    <div className="static-third">
                        <img className="image" src={Bag} alt="Bag" />
                        <img className="image-mobile" src={MobileBag} alt="Bag" />
                    </div>
                    <div className="dynamic-third"></div>
                </article>
                <article>
                    <div className="dynamic-third"></div>
                    <div className="static-third">
                        <img className="image" src={Smile} alt="Smile" />
                        <img className="image-mobile" src={MobileSmile} alt="Smile" />
                    </div>
                    <div className="static-third">
                        <h5 className="third-text">Solicita su préstamo Yolo</h5>
                    </div>
                </article>
                <article>
                    <div className="static-third">
                        <h5 className="fourth-text">¡Listo! Entregale el producto en el momento</h5>
                    </div>
                    <div className="static-third">
                        <img className="image" src={Check} alt="Check" />
                        <img className="image-mobile" src={MobileCheck} alt="Check" />
                    </div>
                    <div className="dynamic-third"></div>
                </article>
            </section>
        </main>
    );
};

export default Guide;