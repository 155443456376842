import "../App.css";
import Navbar from "../components/navbar/Navbar.js";
import Header from "../components/header/Header.js";
import FeaturedShops from "../components/featured-shops/FeaturedShops.js";
import FAQ from "../components/faq/FAQ.js";
import Contact from "../components/contact/Contact.js";
import Footer from "../components/footer/Footer.js";
import BlurredContent from '../components/BlurredContent.js';
import SocialMedia from "../components/SocialMedia.js";
import YoloBot from "../components/chat-bot/chatBot.js";

const QuieroComprar = () => {
    return (
        <div className="size">
            <Navbar />
            <SocialMedia/>
            <YoloBot/>
            <BlurredContent>
                <Header />
                <FeaturedShops />
                <FAQ />
                <Contact />
            </BlurredContent>
            <Footer />
        </div>
    );
}

export default QuieroComprar;
