import { useState } from 'react';
import './FAQ.css';
import LeftGreen from '../../assets/icons/left-green.png';
import DownGreen from '../../assets/icons/down-green.png';

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setSelectedQuestion(selectedQuestion === index ? null : index);
  };

  const faqs = [
    {
      question: '¿Qué es Yolo?',
      answer: 'Somos una Fintech innovadora que ofrece soluciones financieras inmediatas. Con YOLO podés comprar tus productos y pagarlos en cuotas fijas de manera sencilla, rápida y segura.',
    },
    {
      question: '¿Cómo funciona?',
      answer: 'Simplemente elegís el producto que desees comprar y le decís al vendedor que pagás con YOLO. En dos sencillos pasos de verificación de identidad obtenés tu financiamiento, ¡y listo! Sin trámites adicionales. Sin demoras. Y lo mejor, ¡con planes que se ajustan a tu presupuesto!',
    },
    {
      question: '¿Cuáles son los comercios destacados?',
      answer: "Podés visitar cualquier sucursal de Megatone, Aloise, Nuevas Hogar, Chemes, D'Ricco, El Gato, Luciana, Maxihogar, Mobilar, Riiing, Multihogar y Lion Tech.",
    },
    {
      question: '¿Cómo y dónde pago mis cuotas?',
      answer: 'Internet: Home Banking, PagoMisCuentas, LinkPagos, MercadoPago. \nLugares de Pago: Pago Fácil, Rapipago, ProvinciaNet, CobroExpress y PlusPagos.',
    },
    {
      question: '¿Cómo puedo contactarme?',
      answer: 'Podés escribirnos a comercial@yololatam.com',
    },
  ];

  return (
    <div className="faq-background">
      <div className="title-faq">Preguntas frecuentes</div>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleQuestion(index)}>
              <span>{faq.question}</span>
              <img
                src={selectedQuestion === index ? LeftGreen : DownGreen}
                className="faq-icon"
                alt="Toggle"
              />
            </div>
            <div className={`faq-answer ${selectedQuestion === index ? 'show' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
