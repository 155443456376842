import './Header.css';
import HomeImage from '../../assets/images/home.png';
import QuienesSomosImage from '../../assets/images/quienes-somos.png';
import QuieroComprarImage from '../../assets/images/quiero-comprar.png';
import QuieroVenderImage from '../../assets/images/quiero-vender.png';
import { useLocation } from 'react-router-dom';

const Header = () => {

  const location = useLocation().pathname;

  let objectSelectedContent;

  const homeObjectContent = {
    title: "Innovamos, transformamos y revolucionamos el mundo financiero con",
    titleColorText: "soluciones únicas",
    subTitle: "",
    buttonText: "Conocer más",
    buttonColorText: "",
    imgSrc: HomeImage,
    imgAlt: "home-image",
    scrollTarget: '#home',
    extraClassName: 'header-smallerText',
    extraSvg: true,
  }
  const quieroComprarObjectContent = {
    title: "Más cuotas, más beneficios",
    titleColorText: "",
    subTitle: "Comprá en los mejores comercios del país pagando con crédito Yolo",
    buttonText: "Conocé los",
    buttonColorText: "comercios destacados",
    imgSrc: QuieroComprarImage,
    imgAlt: "quiero-comprar-image",
    scrollTarget: '#featuredShops',
    extraClassName: false
  }
  const quierovenderObjectContent = {
    title: "Más ventas, más clientes",
    titleColorText: "",
    subTitle: "Tus clientes tienen un medio de pago en cuotas, solo en 3 minutos, 100% online y al instante",
    buttonText: "Sumá tu",
    buttonColorText: "comercio",
    imgSrc: QuieroVenderImage,
    imgAlt: "quiero-veder-image",
    scrollTarget: '#data',
    extraClassName: false
  }
  const quienesSomosObjectContent = {
    title: "¿Quienés somos?",
    titleColorText: "",
    subTitle: "Yolo es una solución financiera innovadora diseñada para satisfacer las necesidades de un mundo donde la demanda de opciones de pago flexibles y accesibles está en constante crecimiento",
    buttonText: "Conocer más",
    buttonColorText: "",
    imgSrc: QuienesSomosImage,
    imgAlt: "quienes-somos-image",
    scrollTarget: '#about',
    extraClassName: false,
    extraSvg: true,
  }

  switch (location) {
    case '/':
      objectSelectedContent = homeObjectContent
      break;
    case '/quiero-vender':
      objectSelectedContent = quierovenderObjectContent
      break;

    case '/quiero-comprar':
      objectSelectedContent = quieroComprarObjectContent
      break;

    case '/quienes-somos':
      objectSelectedContent = quienesSomosObjectContent
      break;

    default: objectSelectedContent = homeObjectContent
      break;
  }

  const handleScrollToContact = (e, target) => {
    e.preventDefault();
    document.querySelector(target).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <main className='header-container '>
      <div className='squares'>
        <section className='header-halves halve-text'>
          <h2 className={`header-title ${objectSelectedContent.extraClassName && objectSelectedContent.extraClassName}`}>
            {objectSelectedContent.title}
            <span>{objectSelectedContent.titleColorText}</span>
          </h2>
          <p className='header-subTitle'>{objectSelectedContent.subTitle}</p>
          <button onClick={(e) => handleScrollToContact(e, objectSelectedContent.scrollTarget)} className='header-button'>
            {objectSelectedContent.buttonText}
            {
              objectSelectedContent.extraSvg ?
                <svg className='svg' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 12H20M20 12L16 8M20 12L16 16" stroke="#0bff93" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                : <span className='button-text-color'>{objectSelectedContent.buttonColorText}</span>
            }
          </button>
        </section>
        <section className='header-halves'>
          <img alt={objectSelectedContent.imgAlt} src={objectSelectedContent.imgSrc} className='header-images' />
        </section>
      </div>

    </main>
  );
};

export default Header;