import '../App.css';
import Navbar from '../components/navbar/Navbar.js';
import Header from '../components/header/Header.js';
import Guide from '../components/guide/Guide.js';
import Functioning from '../components/functioning/Functioning.js';
import Benefits from '../components/benefits/Benefits.js';
import Contact from '../components/contact/Contact.js';
import Footer from '../components/footer/Footer.js';
import BlurredContent from '../components/BlurredContent.js';
import SocialMedia from '../components/SocialMedia.js';
import YoloBot from '../components/chat-bot/chatBot.js';

const Home = () => {
    return (
        <div className="size">
            <Navbar />
            <SocialMedia/>
            <YoloBot/>
            <BlurredContent>
                <Header />
                <Guide />
                <Functioning />
                <Benefits />
                <Contact />
            </BlurredContent>
            <Footer />
        </div>
    );
}

export default Home;
