import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import QuieroComprar from './pages/QuieroComprar';
import QuieroVender from './pages/QuieroVender';
import QuienesSomos from './pages/QuienesSomos';
import { MenuProvider } from './components/MenuContext';

const App = () => {
    return (
        <MenuProvider>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/quiero-comprar" element={<QuieroComprar />} />
                    <Route path="/quiero-vender" element={<QuieroVender />} />
                    <Route path="/quienes-somos" element={<QuienesSomos />} />
                </Routes>
            </Router>
        </MenuProvider>
    );
};

export default App;
