import './FeaturedShops.css';
import AloiseImage from '../../assets/images/aloise.png';
import MegatoneImage from '../../assets/images/megatone.png';
import NuevasImage from '../../assets/images/nuevas.png';
import chemes from '../../assets/images/chemes.png';
import dricco from '../../assets/images/dricco.png';
import elGato from '../../assets/images/el-gato.jpg';
import luciana from '../../assets/images/luciana.png';
import maxiHogar from '../../assets/images/maxihogar.png';
import mobilar from '../../assets/images/mobilar.png';
import riiing from '../../assets/images/riiing.svg';
import multihogar from '../../assets/images/multihogar.png';
import lion from '../../assets/images/lion-tech.png';

const FeaturedShops = () => {

    return (
        <div className="featured-shops" id='featuredShops'>
            <div className="title-featured-shop">Comercios destacados</div>
            <div className="yolo-images">
                <article>
                    <img src={AloiseImage} alt="YOLO" className="yolo-image" />
                </article>
                <article>
                    <img src={MegatoneImage} alt="YOLO" className="yolo-image-megatone" />
                </article>
                <article>
                    <img src={NuevasImage} alt="YOLO" className="yolo-image-nuevas" />
                </article>
                <article>
                    <img src={chemes} alt="YOLO" className="yolo-image" />
                </article>
                <article>
                    <img src={elGato} alt="YOLO" className="yolo-image" />
                </article>
                <article>
                    <img src={luciana} alt="YOLO" className="yolo-image-megatone" />
                </article>
                <article>
                    <img src={maxiHogar} alt="YOLO" className="yolo-image-nuevas" />
                </article>
                <article>
                    <img src={mobilar} alt="YOLO" className="yolo-image" />
                </article>
                <article>
                    <img src={multihogar} alt="YOLO" className="yolo-image" />
                </article>
                <article>
                    <img src={dricco} alt="YOLO" className="yolo-image-megatone" />
                </article>
                <article>
                    <img src={lion} alt="YOLO" className="yolo-image-megatone" />
                </article>
                <article>
                    <img src={riiing} alt="YOLO" className="yolo-image-megatone" />
                </article>
            </div>
        </div>
    );
};

export default FeaturedShops;