import "./Add.css";

const Add = () => {
    return (
        <div className="background-add" id="home">
            <div className="title-add">Sumá tu comercio<br />
            </div>
            <div className="container-functioning-add">
                <div className="number-box-add">
                    <div className="text-box-add">
                        <div className="number-functioning">#1</div>
                        <div className="text-functioning">Dejanos tus datos<br />
                            y nos comunicaremos<br />
                            con vos<br /></div>
                    </div>
                    <div className="text-box-add">
                        <div className="number-functioning">#2</div>
                        <div className="text-functioning">Te enviamos las<br />
                            especificaciones para<br />
                            sumar tu negocio<br /></div>
                    </div>
                    <div className="text-box-add">
                        <div className="number-functioning">Listo!</div>
                        <div className="text-functioning">Ya podés vender<br />
                            en cuotas con Yolo<br /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Add;