import ChatBot from 'react-simple-chatbot';
import UserIcon from '../../assets/icons/user-chat.png';
import YoloChatIcon from '../../assets/icons/yolo-avatar-chat.png';
import YoloIcon from '../../assets/icons/chat-icon.png';
import Whatsapp from '../../assets/icons/whatsapp-green.png';
import Mail from '../../assets/icons/mail.svg';
import CloseChat from '../../assets/icons/close-chat.png';
import YoloGreen from '../../assets/icons/yolo-green.png';
import './ChatBot.css';
import { ThemeProvider } from 'styled-components';
import { useState, useEffect, useRef } from 'react';

const YoloBot = () => {

  const [opened, setOpened] = useState(false);

  const toggleFloating = () => {
    setOpened(!opened);
  };

  const chatContainerRef = useRef(null); // Referencia al contenedor del chat

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  const theme = {
    background: '#fff',
    fontFamily: 'AmsiPro, sans-serif',
    botBubbleColor: '#F2F2F2',
    botFontColor: '#000',
    userBubbleColor: 'rgba(11, 101, 255, 0.2)',
    userFontColor: '#000',
    overflowY: 'auto'
  };

  const scrollToChatBottom = () => {
    const chatContainer = document.querySelector('.rsc-content'); // El contenedor del chatbot
    
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight; // Se desplaza hacia el final
    }
  };

  let shopMenu = [
    {
      value: 1,
      label: '¿Cómo me integro a Yolo?',
      trigger: () => {
        scrollToChatBottom();

        return 'integration';
      }
    },
    { 
      value: 2,
      label: '¿Cuánto cuesta?',
      trigger: 'cost'
    },
    { 
      value: 3,
      label: '¿Qué sucede si un cliente no paga?',
      trigger: 'morosity'
    },
    {
      value: 4,
      label: '¿Cómo mejora mi negocio?',
      trigger: 'benefits'
    },
    { 
      value: 5,
      label: '¿Cómo me contacto con un asesor comercial?',
      trigger: 'contact-message-response'
    },
  ];

  let clientMenu = [
    {
      value: 1,
      label: '¿Cómo funciona?',
      trigger: () => {
        scrollToChatBottom();

        return 'how-it-works';
      }
    },
    { 
      value: 2,
      label: '¿Dónde puedo comprar?',
      trigger: 'selected-shops'
    },
    { 
      value: 3,
      label: '¿Cómo y dónde pago mis cuotas?',
      trigger: 'how-to-pay'
    },
    {
      value: 4,
      label: '¿Cómo puedo contactarme?',
      trigger: 'contact-message-response'
    }
  ];

  const steps = [
    {
      id: '1',
      message: '¡Hola! Bienvenido a Yolo. Estoy aquí para ayudarte a conocer cómo podés aprovechar nuestra solución financiera. ¿Cómo te gustaría comenzar?',
      trigger: '2',
    },
    {
      id: '2',
      options: [
        { value: 1, label: 'Quiero comprar en cuotas usando Yolo', trigger: 'client' },
        { value: 2, label: 'Estoy interesado en sumar Yolo a mi comercio', trigger: 'shop' },
      ],
    },
    {
      id: 'shop',
      message: '¡Excelente! Yolo es el mejor partner para tu tienda. ¿Qué te gustaría saber?',
      trigger: 'shop-menu'
    },
    {
      id: 'client',
      message: `¿Qué te gustaría saber?`,
      trigger: 'client-menu'
    },
    {
      id: 'client-menu',
      options: clientMenu,
    },
    {
      id: 'shop-menu',
      options: shopMenu,
    },
    {
      id: 'how-it-works',
      message: 'Simplemente elegís el producto que desees comprar y le decís al vendedor que pagás con Yolo. En dos sencillos pasos de verificación de identidad obtenés tu financiamiento, ¡y listo! Sin trámites adicionales. Sin demoras. Y lo mejor, ¡con planes que se ajustan a tu presupuesto!',
      trigger: 'client-menu'
    },
    {
      id: 'selected-shops',
      message: "Podés visitar cualquier sucursal de Megatone, Aloise, Nuevas Hogar, Chemes, D'Ricco, El Gato, Luciana, Maxihogar, Mobilar, Riiing, Multihogar y Lion Tech.",
      trigger: 'client-menu'
    },
    {
      id: 'how-to-pay',
      message: 'Por internet: Home Banking, PagoMisCuentas, LinkPagos, MercadoPago. Acercándote a cualquier: Pago Fácil, Rapipago, ProvinciaNet, CobroExpress y PlusPagos.',
      trigger: 'client-menu'
    },
    {
      id: 'integration',
      message: 'El proceso es sencillo: \n1) Dejás tus datos y nos comunicamos con vos.\n2) Te enviamos las especificaciones para sumar tu negocio.\n3) ¡Listo! Ya podés vender en cuotas con Yolo.',
      trigger: 'shop-menu'
    },
    {
      id: 'cost',
      message: '¡No cobramos comisión! Yolo garantiza términos de financiamiento claros y transparentes, sin cargos ocultos.',
      trigger: 'shop-menu'
    },
    {
      id: 'morosity',
      message: 'No te preocupes, Yolo se encarga de cobrar, y vos te enfocás en vender.',
      trigger: 'shop-menu'
    },
    {
      id: 'benefits',
      message: 'Sumás más clientes con una potente herramienta de ventas y fidelizás a tus clientes con nuestras estrategias publicitarias.',
      trigger: 'shop-menu'
    },
    {
      id: 'contact-message',
      message: '¿Querés saber más? Comunicate con un asesor de Yolo',
      trigger: 'contact-action'
    },
    {
      id: 'contact-message-response',
      message: 'Podés contactarte por los siguiente medios:',
      trigger: 'contact-action'
    },
    {
      id: 'contact-action',
      component: (
        <div className='custom-component'>
          <a target='blank' href='https://wa.me/+5491123278925'>Por Whatsapp <img src={Whatsapp} alt='wpp icon'/></a>
          <a target='blank' href='mailto:contacto@yololatam.com'>Por mail <img src={Mail} alt='mail icon'/></a>
        </div>
      ),
      end: true
    }
  ];

  useEffect(() => {
    const chatContainer = document.querySelector('.rsc-content'); // El contenedor del chatbot
    if (chatContainer) {

      chatContainer.scrollTop = chatContainer.scrollHeight; // Se desplaza hacia el final
    }
  }, [steps]);

  return (
    <ThemeProvider theme={theme} >
      <ChatBot
        className='yolo-bar'
        steps={steps}
        floating={true}
        botAvatar={YoloChatIcon}
        userAvatar={UserIcon}
        floatingIcon={<img src={YoloIcon} alt='yolo icon' className='yolo-chat-icon' />}
        opened={opened}
        toggleFloating={toggleFloating}
        floatingStyle={{ right: '2%', bottom: '3%', margin: '0px' }}
        submitButtonStyle={{ color: "#fff", width: '100px' }}
        placeholder=''
        hideSubmitButton={true}
        headerComponent={<section className='yolo-bot'>
          <div className='chat-title'>
            <img src={YoloGreen} alt='Yolo logo' style={{ width: '50px', height: 'auto' }} />
            <p>Asistente virtual</p>
          </div>
          <button onClick={(toggleFloating)} type='button'><img src={CloseChat} alt='Close chat bot' /></button>
        </section>}
      />
    </ThemeProvider>
  )
}

export default YoloBot;